/**
 *   全局变量配置-定制主题
 */

@import '../../../assets/styles/globalVariables/color';
@import '../../../assets/styles/globalVariables/fontSize';
@import '../../../assets/styles/globalVariables/borderRadius';
@import '../../../assets/styles/globalVariables/spacing';

.ant-menu-item,
.ant-menu-submenu {
  border-radius: 0px !important;
}
.ant-menu-item-only-child::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 100%;
  outline: 1px solid $colorPrimaryHover;
  margin-right: 10px;
}
.ant-menu-sub .ant-menu-submenu-selected {
  background: #71a1fd !important;
}
.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: $colorPrimary !important;
}
.ant-menu-item-selected {
  background: $colorPrimary !important;
  color: #ffffff !important;
}

.ant-menu-item-active:active {
  background: transparent !important;
}
.ant-menu-submenu-title:active,
.ant-menu-submenu-title:hover {
  background-color: transparent !important;
}
.ant-menu-submenu-selected
  .ant-menu-submenu-title
  + ul
  .ant-menu-item-selected {
  background: $colorPrimary !important;
  color: #ffffff !important;
}
.ant-menu-submenu-inline
  .ant-menu-submenu-title
  + ul
  .ant-menu-item-selected::before {
  background: #ffffff;
  outline: 1px solid #ffffff;
  transition: background 0.6s, outline 0.6s;
}
.ant-menu-submenu .ant-menu-submenu-title + ul .ant-menu-item-active:active {
  background: transparent !important;
}
.ant-menu-light {
  color: $colorPrimaryHover;
  font-weight: 400;
  font-size: 16px;
}
/* hover start*/
.ant-menu-sub > .ant-menu-item-active {
  color: $colorPrimary !important;
}
.ant-menu-light
  > .ant-menu-item:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  color: $colorPrimary !important;
}
.ant-menu-light
  .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  color: $colorPrimary;
}
/* hover end*/