/**
 *   全局变量配置-定制主题
 */

@import '../../../assets/styles/globalVariables/color';
@import '../../../assets/styles/globalVariables/fontSize';
@import '../../../assets/styles/globalVariables/borderRadius';
@import '../../../assets/styles/globalVariables/spacing';

.history_box {
    flex: 1;
    height: 100%;
    user-select: none;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    margin: 0px 20px;
    > .item {
        display: inline-block;
        height: 100%;
        margin: 0px 10px;
        font-size: $defaultFontSize-14;
        color: $colorPrimaryTextBg;
        position: relative;
        transition: all 0.3s;
    }
    > .item:hover {
        color: $colorPrimary;
        cursor: pointer;
    }
    > .item > span:nth-of-type(1) {
        margin-right: 5px;
    }
    > .item_active {
        color: $colorPrimary;
    }
    > .item_active::before {
        content: '';
        position: absolute;
        display: block;
        bottom: 1px;
        left: 0px;
        width: 100%;
        height: 2px;
        background: $colorPrimary;
    }
    &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 2px;
        box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
        background: $scrollbar-thumb-color;
    }
    &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
        border-radius: 2px;
        background: $scrollbar-track-color;
    }
}
