/**
 *   全局变量配置-定制主题
 */

@import '../../assets/styles/globalVariables/color';
@import '../../assets/styles/globalVariables/fontSize';
@import '../../assets/styles/globalVariables/borderRadius';
@import '../../assets/styles/globalVariables/spacing';

html,
body {
    margin: 0px;
    padding: 0px;
}
.login_container {
    position: relative;
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    overflow-x: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;

    > .login_bg {
        width: 100%;
        min-height: 100vh;
        max-height: 100vh;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 10;
        > svg {
            width: 100%;
            height: 100%;
        }
    }
    > .login_bg_vice {
        bottom: -120px;
        z-index: 5;
        min-height: none;
        max-height: none;
    }
    > .inset {
        position: relative;
        z-index: 20;
    }
    > .from_container {
        position: relative;
        width: 407px;
        // width: 40vmin;
        // height: 50vmin;
        // height: 450px;
        right: 5%;
        z-index: 25;
        display: flex;
        flex-direction: column;
        > p {
            font-weight: 500;
            font-size: 25px;
            color: #333333;
            margin: 5px 0px;
            letter-spacing: 2px;
            > span {
                color: #504ebe;
            }
        }
        > div {
            position: relative;
            background-color: #fff;
            height: 330px;
            // height: 100%;
            border-radius: 16px;
            margin-top: 16px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;

            .input_box {
                position: relative;
                display: flex;
                align-items: center;
                padding-bottom: 7px;

                &::after {
                    position: absolute;
                    content: '';
                    bottom: 0px;
                    right: 0px;
                    height: 1px;
                    width: 100%;
                    background-color: #dddddd;

                    &:focus {
                        background-color: #0826ce;
                    }
                }
            }
            .submit {
                display: flex;
                justify-content: center;
                > button {
                    width: 234px;
                    height: 42px;
                    border-radius: 30px;
                    font-size: 16px;
                }
            }
        }
    }
}
