/**
 *   全局变量配置-定制主题
 */

@import '../../../assets/styles/globalVariables/color';
@import '../../../assets/styles/globalVariables/fontSize';
@import '../../../assets/styles/globalVariables/borderRadius';
@import '../../../assets/styles/globalVariables/spacing';

.user-center {
    width: auto;
    height: 40px;
    box-sizing: border-box;
    margin-right: 50px;
    display: flex;
    align-items: center;
    position: relative;
}

.user-img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.user-name {
    color: #333333;
    font-size: 12px;
    margin-left: 10px;
    user-select: none;
}

.triangle {
    margin-left: 10px;
    transform: rotateZ(180deg);
    transition: transform 0.2s;
}

.triangle-active {
    transform: rotateZ(0deg);
}

.user-popup-window {
    width: 320px;
    height: 462px;
    margin-top: 57px;
    background-color: #fff;
    border-radius: 12px 0px 12px 12px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 40px;
    position: relative;

    > .user-popup-cancel {
        position: absolute;
        right: 11px;
        top: 9px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $colorPrimaryText;
        &:hover {
            cursor: pointer;
        }
    }
    .popup-img {
        width: 68px;
        height: 68px;
        border-radius: 100%;
    }
    > .popup-tilte {
        font-weight: 400;
        font-size: $fontSizeHeading-20;
        color: $colorPrimaryText;
        margin: 0px;
    }
    > .popup-line {
        width: 100%;
        height: 1px;
        background: #dddddd;
        margin: 30px 0px;
    }
    > .popup-source-container {
        > .popup-source {
            font-size: $defaultFontSize-14;
            font-weight: 400;
            color: $colorPrimaryTextBg;
        }
    }
    > .popup-set-container {
        width: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        > .popup-set-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $colorPrimaryTextBg;
            border-radius: $defaultBorderRadius;
            padding: 8px;
            transition: background-color 0.5s;
            > span {
                color: inherit;
            }

            &:hover {
                background-color: $colorPrimaryBg;
                cursor: pointer;
            }
        }
    }
    > .popup-confirm-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        > p:nth-of-type(1) {
            font-size: $fontSizeHeading-18;
            color: $colorPrimaryText;
        }
        > p:nth-of-type(2) {
            font-size: $richTextFontSize-12;
            color: $colorPrimaryRichText;
        }
        > div {
            margin-top: 20px;
        }
    }
    > .popup-modify-capacitor {
        width: 100%;
        .input_box {
            position: relative;
            display: flex;
            align-items: center;
            padding-bottom: 7px;

            &::after {
                position: absolute;
                content: '';
                bottom: 0px;
                right: 0px;
                height: 1px;
                width: 100%;
                background-color: #dddddd;

                &:focus {
                    background-color: #0826ce;
                }
            }
        }
        .submit {
            display: flex;
            justify-content: center;
        }
    }
}

.ant-tooltip-inner:has(.user-popup-window) {
    box-shadow: none;
}

.popup-redirection-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .pw-container {
        display: flex;
        align-items: center;
        > .pw {
            font-size: $fontSizeHeading-18;
            color: $colorPrimaryText;
            margin-left: 6px;
        }
    }
    > p {
        font-size: $richTextFontSize-12;
        color: $colorPrimaryRichText;
    }
}

.popup-modify-picture {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    > p {
        width: 100%;
        font-size: $defaultFontSize-14;
        color: $colorPrimaryRichText;
        align-items: flex-start;
    }
    > div {
        display: flex;
        justify-content: center;
        margin: 20px 0px;

        > .upload-img {
            width: 80px;
            height: 80px;
            border-radius: 0px;
        }
        .popup-upload-hover:hover {
            cursor: pointer;
        }

        &:nth-of-type(2) {
            margin-top: 20px;
        }
    }
}
