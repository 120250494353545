$colorPrimary: #504ebe; //主色
$colorPrimaryHover: #8c8bdc; //主色悬浮态
$colorPrimaryActive: #4544bf; //主色鼠标按下激活态
$colorPrimaryBg: #f1eeff; //主色浅色背景色
$colorPrimaryText: #333333; //主色文本
$colorPrimaryTextBg: #666666; //主色文本浅色
$colorPrimaryTextActive: #ffffff; //主色文本悬浮态
$colorPrimaryRichText: #999999; //主色 富文本

$scrollbar-thumb-color: #bebdff; //滚动条里面小方块
$scrollbar-track-color: #f1eeff; //滚动条里面轨道
