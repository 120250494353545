/**
 *   全局变量配置-定制主题
 */

@import 'globalVariables/color';
@import 'globalVariables/fontSize';
@import 'globalVariables/borderRadius';
@import 'globalVariables/spacing';

html,
body {
    margin: 0px;
    padding: 0px;
}

.template-navigation-bar {
    background-color: #fff;
    height: 70px;
    border-radius: 6px;
    margin-bottom: 14px;
    display: flex;
    padding: 0px $defaultPadding;
    justify-content: space-between;
    align-items: center;
}

.template-content-block {
    /* height: 740px; */
    height: calc(98% - 70px - 50px);
    width: 100%;
    border-radius: 6px;
    // padding: $defaultPadding 0px;
    overflow-x: auto;
    overflow-y: auto;
}
.template-footer {
    max-height: 50px;
    padding-top: 10px;
}
.template-bg {
    height: calc(98% - 70px);
    background-color: #fff;
}

.bar-name {
    display: flex;
    align-items: center;
    > .bar-name-title,
    .bar-separator {
        font-size: $fontSizeHeading-20;
        color: $colorPrimaryTextBg;
    }
    > span:nth-of-type(2) {
        font-size: $fontSizeHeading-18;
        color: $colorPrimaryTextBg;
        margin-left: 10px;
        margin-right: 10px;
    }
    > span:nth-of-type(3) {
        font-weight: 600;
    }
    > .bar-nav-left-slot {
        margin-left: 22px;
    }
}

.name-meun2.name-meun2 {
    font-size: $fontSizeHeading-20;
    color: $colorPrimaryText;
}

.bar-nav-right-slot {
    display: flex;
    align-items: center;
}

//表格
th.columns-color {
    background-color: $colorPrimaryBg !important;
}
.columns-color {
    color: $colorPrimaryTextBg;
}
:where(.template-content-block) .myTableStyle {
    // 渠道列表-表格样式
    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-table,
    .ant-table-container {
        height: inherit;
    }
}

//分页器
.ant-pagination .ant-pagination-item {
    background-color: #ffffff;
}
.ant-pagination .ant-pagination-item-active {
    background-color: $colorPrimary !important;
    > a {
        color: #fff;
    }
    &:hover > a {
        color: #fff;
    }
}

// 抽屉
.enterprise-drawer {
    display: flex;
    justify-content: space-between;
}

.enterprise-drawer-title {
    font-size: $fontSizeHeading-18;
    line-height: 30px;
    color: #303030;
    font-weight: 500;
}

//  Tooltip
:where(.css-dev-only-do-not-override-riih6w).ant-tooltip .ant-tooltip-inner {
    color: #0fb433;
}
.ant-popover-inner:has(.preview_window_flag),
.ant-tooltip-inner:has(.AndroidItem) {
    background-color: transparent;
    box-shadow: none;
}

//新建按钮样式
.my_ant_btn {
    display: flex;
    align-items: center;
    > svg {
        margin-right: 12px;
    }
}

// 搜索按钮
.search_box {
    display: flex;
    width: 250px;
}

//表格 - 筛选icon
.columns-my-ant {
    > .ant-table-filter-column {
        justify-content: flex-start;

        > .ant-table-column-title {
            flex: none;
        }
        > .ant-table-filter-trigger {
            left: 10%;
        }
    }
}
:where(.css-dev-only-do-not-override-154a2ez).ant-btn-link {
    color: $colorPrimary;
    &:not(:where(.css-dev-only-do-not-override-154a2ez).ant-btn-link:disabled) {
        color: $colorPrimary;
    }
}

//Select多选options样式
:where(.css-dev-only-do-not-override-154a2ez).ant-select-multiple .ant-select-selection-item {
    background: $colorPrimaryBg;
}

// 预览窗口的文字气泡里面的link按钮样式
.preview_window_text {
    color: #69799a;

    &:hover {
        cursor: pointer;
    }
}

// 全局
::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 14px;
}
::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 2px;
    box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
    background: $scrollbar-thumb-color;
}
::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
    border-radius: 2px;
    background: $scrollbar-track-color;
}