/**
 *   全局变量配置-定制主题
 */

@import '../../assets/styles/globalVariables/color';
@import '../../assets/styles/globalVariables/fontSize';
@import '../../assets/styles/globalVariables/borderRadius';
@import '../../assets/styles/globalVariables/spacing';

.err_page {
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > p {
        font-size: $FontSize-16;
        color: $colorPrimaryRichText;
    }
    > button {
        color: $colorPrimary;
        font-size: $defaultFontSize-14;
        border-radius: 30px;
        border: 1px solid $colorPrimary;
        background: none;
        margin-top: 10px;
        padding: 8px 40px;

        &:hover {
            border: 1px solid $colorPrimaryHover;
            color: $colorPrimaryHover;
        }
        &:active{
            border: 1px solid $colorPrimaryActive;
            color: $colorPrimaryActive;
        }
    }
}
