/**
 *   全局变量配置-定制主题
 */

@import '../../../../assets/styles/globalVariables/color';
@import '../../../../assets/styles/globalVariables/fontSize';
@import '../../../../assets/styles/globalVariables/borderRadius';
@import '../../../../assets/styles/globalVariables/spacing';

.loading{
    position: absolute;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}