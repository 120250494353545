/**
 *   全局变量配置-定制主题
 */

@import '../../../../assets/styles/globalVariables/color';
@import '../../../../assets/styles/globalVariables/fontSize';
@import '../../../../assets/styles/globalVariables/borderRadius';
@import '../../../../assets/styles/globalVariables/spacing';

.logo_content {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 12px;
  > .title {
    margin-left: 10px;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > .main_title {
      font-size: $fontSizeHeading-20;
      font-weight: 700;
      color: $colorPrimary;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      user-select: none;
    }
    > .subtitle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      font-size: 9px;
      font-weight: 500;
      color: rgba(120, 160, 255, 0.5);
      user-select: none;
    }
  }
}
