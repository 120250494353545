/**
 *   全局变量配置-定制主题
 */

@import '../../assets/styles/globalVariables/color';
@import '../../assets/styles/globalVariables/fontSize';
@import '../../assets/styles/globalVariables/borderRadius';
@import '../../assets/styles/globalVariables/spacing';

.posters_container {
    width: 100%;
    height: 100vmax;
    > div {
        width: inherit;
        height: inherit;
        > div {
            position: absolute;
            width: 13rem;
            height: 13rem;
            top: 35%;
            left: 50%;
            transform: translateX(-50%);
            &[class*='style3'] {
                top: 45%;
                width: 14rem;
                height: 14rem;
            }
            &[class*='style4'] {
                top: 42%;
                left: 72%;
                width: 9rem;
                height: 9rem;
            }
        }
    }
}
